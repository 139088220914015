<template>
    <div class="loader_box">
        <div :style="{height:size+'px',width:size+'px'}">
            <LazyImage
            v-if="checkFileType(CustomLoaderMedia) == 'image'"
              :key="CustomLoaderMedia"
              :src="CustomLoaderMedia"
              class="w-100 h-100 "
              style=" object-fit: contain;"
              alt="Quizell Custom Loader Image"
            />
            <video
                      v-if="checkFileType(CustomLoaderMedia) == 'video'"
                      ref="videoRef"
                      :key="CustomLoaderMedia"
              :src="CustomLoaderMedia"
              class="w-100 h-100 "
              style=" object-fit: contain;"
              alt="Quizell Custom Loader Image"
                      controls></video>
        </div>
    </div>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
  data(){
    return{
      size: this.$route.query.size?this.$route.query.size:100

    }
  },
    props:['selectedColor','getCustomLoaderImage'],

    methods:{
        getLoaderColor(color){
            return `--loaderColor:${color}`
        },
        checkFileType(file) {
  let fileType = "unknown"; 
  let allowedImageExtensions = /\.(jpg|jpeg|png|gif)(\?.*)?$/i; 
  let allowedVideoExtensions = /\.(mp4)(\?.*)?$/i; 

  if (allowedImageExtensions.test(file)) {
    fileType = "image";
  } else if (allowedVideoExtensions.test(file)) {
    fileType = "video";
  }

  return fileType;
}
    },
    computed:{
        // ...mapGetters(['getQuizLoader']),
  // QuizLoaderData(){
  //     return this.getQuizLoader
  //   },

  CustomLoaderMedia(){
    return this.getCustomLoaderImage 
  },
    }
};
</script>

<style scoped>
.loader_box{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index:999
}

</style>
